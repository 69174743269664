import React from 'react';
import Feature from '../../components/sections/industry/feature-block';
import { useStaticQuery, graphql } from 'gatsby';
import { Benefits } from '../../models/benefit';
import BenefitSection from '../../components/sections/industry/benefit';
import WhyPatronscan from '../../components/sections/why-patronscan';
import SEO from '../../components/seo';
import ConnectWithUs from '../../components/sections/connect-with-us';
import './property-management.css';
import HeroBanner from '../../components/sections/hero-banner';
import { getDemoLink } from '../../services/demo-selector';
import { Route } from '../../models/site-links';

const PropertyManagement = () => {
  const data = useStaticQuery(graphql`
    query {
      countertopPlus: file(relativePath: { eq: "product-card/countertop-white.png" }) {
        childImageSharp {
          fixed(height: 118) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      kioskPlus: file(relativePath: { eq: "product-card/kiosk-plus.png" }) {
        childImageSharp {
          fixed(height: 118) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      fraud: file(relativePath: { eq: "anti-fraud/Fraudster-latest.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      barcodeScanning: file(relativePath: { eq: "feature-images/fake-ids.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      realtor: file(relativePath: { eq: "feature-images/realtor.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blackCountertop: file(relativePath: { eq: "benefit-images/black-countertop.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      propertyAlert: file(relativePath: { eq: "benefit-images/property-alert.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      demographics: file(relativePath: { eq: "benefit-images/demographics.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fakeIDBanner: file(relativePath: { eq: "hero-images/fakeID-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      barcodesEasyFake: file(relativePath: { eq: "feature-images/barcode_no_bueno.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      marketingInsight: file(relativePath: { eq: "property-management/marketing-insights.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const benefits: Benefits[] = [
    {
      image: data.blackCountertop.childImageSharp.fluid,
      benefits: [
        {
          heading: 'Advanced Multi-Layer ID Verification',
          text: 'Fake IDs get better everyday, so does Patronscan.  Our proprietary software scans the front of the ID and identifies which IDs are real and which are fake. Scanners that only scan barcodes are easily fooled. Using Patronscan is the best way to protect yourself from fraudsters.',
        },
        {
          heading: 'Read All Types of Identification',
          text: 'You want your ID scanner to be as effective as possible, which means scanning all types of IDs. We scan all types of government issues IDs including: driver’s licenses, military cards, passports and international IDs. Patronscan has the ability to scan over 4,800 different IDs.',
        },
        {
          heading: 'High Speed Scanning',
          text: 'Patronscan makes checking IDs simple. In 3-4 seconds your staff will know everything they need to about the potential customer standing in front of them. Verify your customers identity without creating more work for your staff.',
        },
      ],
    },
    {
      image: data.propertyAlert.childImageSharp.fluid,
      benefits: [
        {
          heading: 'Powerful Shared Flag Network',
          text: 'Identifying fraudsters before they act is difficult. The Patronscan network will alert you when someone has been flagged by another location for fraudulent behavior.  Your locations are notified of exactly when and why the flag was placed, preventing further damages.',
        },
        {
          heading: 'Scan History',
          text: 'Using Patronscan at your locations will provide better insights into your customers, increasing their accountability.   Your scan history includes names and live images making it easy to go through and point out exactly who was causing trouble at your location.',
        },
      ],
    },
    {
      image: data.demographics.childImageSharp.fluid,
      benefits: [
        {
          heading: 'Demographic Reporting',
          text: 'A Patronscan ID Scanner will provide you with powerful demographic reports, allowing you to analyze your property information to better target your marketing spend, ensuring you will fill your vacant units quickly.',
        },
        {
          heading: 'Privacy & Fair Housing Compliance',
          text: 'We comply with all North American privacy laws while verifying the ID of your potential tenant. Patronscan can help prove compliance with fair housing regulations by ensuring that your team has met all the proper standards.  You can feel confident that all data collected is encrypted and stored in highly secured facilities. Similar to the banking industry, all data resides in a SSAE 16 type 2 facility. ',
        },
      ],
    },
  ];
  let sectionCounter = 0;
  return (
    <div>
      <SEO
        title="ID Scanner for Bars - Patronscan"
        description="Fake IDs are getting better. Prevent rental fraud with Patronscan’s advanced ID scanners. Identify individuals on the do-not rent (DNR) list."
      />
      <section>
        <HeroBanner
          heroImage={data.fakeIDBanner.childImageSharp.fluid}
          heading="Trusted ID Verification for Property Managers"
          subHeading="Catch fake IDs with the world's most trusted ID scanners."
          videoLink="https://player.vimeo.com/video/366101059?autoplay=1&loop=1&autopause=0&muted=1"
          showVideo
          linkText="Book Demo"
          link={getDemoLink()}
          buttonId="PropertyHero:BookDemo"
        />
        <Feature
          image={data.fraud.childImageSharp.fluid}
          heading="Most fraud runs"
          headingThin="through fake IDs"
          subHeading="Synthetic Identity fraud is one of the fastest-growing types of ID fraud in the world."
          description="Fraudsters use a combination of real and fake personal data to create identities to defraud property managers. They rely on fake IDs to steal hardware and SIM cards, costing millions in fraud."
          linkText="Contact Us"
          link={Route.TalkToAnExpert}
          buttonId="PropertyFeature:FraudRunsFakeID"
        />
        <Feature
          rightAlign
          image={data.barcodesEasyFake.childImageSharp.fluid}
          heading="Barcode scanning"
          headingThin="is not good enough"
          subHeading="Fake ID websites guarantee that their IDs will pass any barcode scanner or your money back."
          description="A barcode is only one point of reference. Our authentication process compares over 6,000 data points to verify it is real. With Patronscan you can be confident that you’re catching quality fakes."
          linkText="Book Demo"
          link={getDemoLink()}
          buttonId="PropertyFeature:BarcodeScanningIsNotGood"
        />
        <Feature
          image={data.marketingInsight.childImageSharp.fluid}
          heading="Marketing insights"
          headingThin="that lead to success"
          subHeading="Employ data-driven marketing efforts with real-time reporting of traffic and demographic information."
          description="Get daily demographic reports to target the audiences most likely to sign while optimizing marketing spend."
          linkText="Contact Us"
          link={Route.TalkToAnExpert}
          buttonId="PropertyFeature:MarketingInsights"
        />
      </section>
      <section>
        <h2 className="p-4 lg:p-16 gradient-text text-3xl lg:text-6xl leading-tight font-hairline font-heading">
          More Patronscan Benefits
        </h2>
      </section>
      <section>
        {benefits.map((benefit) => {
          sectionCounter++;
          return (
            <BenefitSection benefits={benefit} alignRight={sectionCounter % 2 ? true : false} />
          );
        })}
      </section>
      <div className="property-why-patronscan">
        <WhyPatronscan />
      </div>
      <ConnectWithUs
        buttonId="PropertyConnect"
        promptText="Get in touch for pricing information and or see how Patronscan protects property managers with a live demo."
      />
    </div>
  );
};

export default PropertyManagement;
